
import { useVModel } from "@vueuse/core";
import { defineComponent, reactive, ref } from "vue";
import { useUpload, UfUploadFileType } from "@/composables";
import { fireService } from "@/service";
import { Toast } from "vant";
type FireResult = {
  id: string;
  name: string;
}[];
export default defineComponent({
  name: "Remarks",
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  emits: ["submit"],
  setup(props, { emit }) {
    const innerShow = useVModel(props, "show", emit);
    const files = ref<UfUploadFileType[]>([]);
    const fireResult = ref<FireResult>([]);
    const getFireResult = async () => {
      const { data } = await fireService.fireResult();
      fireResult.value = data.data;
    };
    getFireResult();
    const state = reactive({
      close_result_one: "",
      pic_ids: "",
      opt_content: "",
      result_desc: ""
    });
    const submit = () => {
      if (!state.close_result_one) {
        Toast.fail("请选择备注结果");
        return;
      }
      state.pic_ids = files.value.map(file => file.res.id).join(",");
      emit("submit", state);
      innerShow.value = false;
    };
    const { upload } = useUpload();
    return {
      files,
      upload,
      fireResult,
      innerShow,
      state,
      submit
    };
  }
});
