
import { fireService } from "@/service";
import { defineComponent, toRefs, ref, reactive, watch, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
import _ from "lodash";
import { Dialog, ImagePreview, Toast } from "vant";
import { RouterName } from "@/router";
import Approval from "@/views/events/components/Approval.vue";
import Remarks from "./components/Remarks.vue";
interface Remarks {
  close_result_one: string;
  pic_ids: string;
  opt_content: string;
  result_desc: string;
}
interface UserInfo {
  department_name: string;
  name: string;
  head_pic: string;
  id: string;
  _state: number;
}
interface EventLog {
  show_content: string;
  opt_time_name: string;
  opt_uid_array: UserInfo[];
}
type CloseInfo = {
  copy_lists: any[];
  fire_linkage_name: string;
  fire_alarm_name: string;
  close_result_name: string;
  close_time_name: string;
  close_time_consuming: string;
  opt_content: string;
  pic_ids_lists: Approval.FileRes[];
  approval_uids_do_array: UserInfo[];
  approval_uids_array: UserInfo[];
};

type OptList = {
  button_key: string;
  button_name: string;
  is_common: string;
  desc: string;
};

type Operation = {
  code: string;
  create_time_name: string;
  icon: string;
  name: string;
  state: string;
  id: string;
  state_name: string;
  type: 2;
};
type Operation2 = {
  cause: string;
  fault_time_name: string;
  faulttype_name: string;
  id: string;
  orderid: string;
  repair_user_array: [];
  repairstate: string;
  repairstate_name: string;
  type: 1;
};
type HandleInfo = {
  accept_time_name: string;
  handler_uid_array: UserInfo[];
  operation_lists: (Operation | Operation2)[];
};
const getDefaultForm = () => ({
  id: "",
  fire_state_name: "",
  fire_type_name: "",
  fire_code: "",
  close_state: "",
  is_overtime_name: "",
  is_overtime: "",
  event_log: [] as EventLog[],
  fire_time_name: "",
  close: {} as CloseInfo,
  opt_lists: [] as OptList[],
  handler: {} as HandleInfo,
  fire_parts_lists: []
});
type FireInfo = ReturnType<typeof getDefaultForm>;
export default defineComponent({
  name: "FireDetail",
  components: {
    Approval,
    Remarks
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const fireInfo = reactive<FireInfo>(getDefaultForm());
    const overlayShow = ref(false);
    const showLock = ref(false);
    const getEventDetail = async () => {
      if (!route.params.id) return;
      try {
        overlayShow.value = true;
        const { data } = await fireService.fireDetail(route.params.id as string);
        Object.assign(fireInfo, data.data);
      } catch (error) {
        if (error.state === "014") {
          showLock.value = true;
        }
        Object.assign(fireInfo, getDefaultForm());
      }
      overlayShow.value = false;
    };

    getEventDetail();

    watch(
      () => route.params.id,
      () => {
        if (route.name != RouterName.FIRE_DETAIL) return;
        fireInfo.id = "";
        getEventDetail();
      }
    );
    // 页面的审核人
    const approvalList = computed<UserInfo[]>(() => {
      if (!fireInfo.close.approval_uids_array || !fireInfo.close.approval_uids_do_array) return [];
      if (fireInfo.close.approval_uids_array.length > 0) {
        fireInfo.close.approval_uids_array.forEach((item, index) => {
          if (index === 0) {
            item._state = 2;
            return;
          }
          item._state = 3;
        });
      }
      if (fireInfo.close.approval_uids_do_array.length > 0) {
        fireInfo.close.approval_uids_do_array.forEach(item => {
          item._state = 1;
        });
      }
      return [...fireInfo.close.approval_uids_do_array, ...fireInfo.close.approval_uids_array];
    });
    // 跳转到工单或者审批详情
    const toOperationDetail = (type: number, id: string) => {
      if (type === 1) {
        // eslint-disable-next-line no-undef
        wx.miniProgram.navigateTo({
          url: `/pages/guaranteeDetil/main?id=${id}`
        });
        return;
      }
      router.push({
        name: RouterName.APPROVAL_DETAIL,
        params: {
          id
        }
      });
    };

    // 审核人弹窗
    const approvalState = reactive({
      show: false
    });
    // 评论人弹窗
    const remarkState = reactive({
      show: false,
      submit(state: Remarks) {}
    });
    const buttonClick = async (key: string) => {
      try {
        switch (key) {
          case "9": {
            remarkState.show = true;
            const state = await new Promise<Remarks>(resolve => {
              remarkState.submit = state => {
                resolve(state);
              };
            });
            await fireService.addRemarks({
              id: fireInfo.id,
              ...state
            });
            Toast.success("备注成功");
            break;
          }
          default:
            break;
        }
        fireInfo.id = "";
        getEventDetail();
      } catch (error) {}
    };
    return {
      moment,
      ImagePreview,
      buttonClick,
      approvalState,
      approvalList,
      overlayShow,
      toOperationDetail,
      remarkState,
      showLock,
      innerOptLists: computed(() => {
        return fireInfo.opt_lists.filter(item => item.button_key === "9");
      }),
      fireListShow: ref(false),
      ...toRefs(fireInfo)
    };
  }
});
